<template>
    <div class="block-xs--sm-bottom col-12">
        <div class="row no-gutters">
            <toggle 
                v-model="dayType" 
                :radio="true" 
                radioValue="all-day" 
                class="sub-label block-xs--xs col-12" 
                multiLabel="All day" />
        </div>

        <div class="row no-gutters align-items-center">
            <div class="col-auto">
                <toggle 
                    v-model="dayType" 
                    :radio="true" 
                    radioValue="custom" 
                    class="sub-label block-xs--xs-right" 
                    multiLabel="Custom" />
            </div>

            <div 
                v-if="dayType === 'custom'" 
                class="block-md--md-left block-xs--xs-top col-auto">
                <span class="block-xs--xs-right">From</span>
                <time-picker
                    v-model="dayStart"
                    class="d-inline-block block-xs--md-right"
                    simple
                />

                <span class="block-xs--xs-right">To</span>
                <time-picker
                    v-model="dayEnd"
                    class="d-inline-block"
                    simple
                />

                <div class="block-xs--xs-top">
                    <span class="block-xs--xs-right">Next Day</span>
                    <toggle 
                        v-model="nextDay" 
                        :slider="true" 
                        class="d-inline-block" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Toggle from 'components/cleverly/Toggle'
import TimePicker from 'components/TimePicker'

export default {
    name: 'DayTypePicker',
    components: {
        Toggle,
        TimePicker,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        start: {
            type: String,
            required: true,
        },
        next: {
            type: Boolean,
            required: true,
        },
        end: {
            type: String,
            required: true,
        },
    },
    computed: {
        dayType: {
            get() {
                return this.type
            },
            set(val) {
                this.sendUpdate({type: val})
            },
        },
        dayStart: {
            get() {
                return this.start
            },
            set(val) {
                this.sendUpdate({start: val})
            },
        },
        dayEnd: {
            get() {
                return this.end
            },
            set(val) {
                this.sendUpdate({end: val})
            },
        },
        nextDay: {
            get() {
                return this.next
            },
            set(val) {
                this.sendUpdate({nextDay: val})
            },
        },
    },
    methods: {
        sendUpdate(changed = {}) {
            const payload = {
                type: this.type,
                start: this.start,
                end: this.end,
                nextDay: this.next,
            }

            this.$emit('updated', Object.assign(payload, changed))
        },
    },
}
</script>
