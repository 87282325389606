<template>
    <div class="row no-gutters">
        <div class="col-12">
            <div class="group-inputs block-xs--md-bottom row no-gutters">
                <toggle
                    v-model="schedule"
                    :radio="true" 
                    class="col-auto"
                    radioValue="everyday"
                    multiLabel="Everyday" />

                <toggle
                    v-model="schedule"
                    :radio="true"
                    class="block-xs--sm-left col-auto"
                    radioValue="custom"
                    multiLabel="Custom" />
            </div>

            <line-item 
                v-if="schedule === 'everyday'" 
                class="label-offset block-xs--md-top row no-gutters">
                <div class="col-12 block-xs--md-bottom">
                    <div class="block-xs--xs-bottom row no-gutters">
                        <toggle 
                            v-model="type" 
                            :radio="true" 
                            radioValue="all-day" 
                            class="sub-label col-auto" 
                            multiLabel="All day" />
                    </div>

                    <div class="block-xs--md-right row no-gutters align-items-center">
                        <div class="col-auto">
                            <toggle 
                                v-model="type" 
                                :radio="true" 
                                radioValue="custom" 
                                class="sub-label block-xs--xs-right" 
                                multiLabel="Custom" />
                        </div>

                        <div class="block-md--md-left block-xs--xs-top col-auto">
                            <span class="block-xs--xs-right">From</span>
                            <TimePicker 
                                :disabled="type === 'all-day'" 
                                v-model="startTime" 
                                class="d-inline-block block-xs--md-right" />
                            <span class="block-xs--xs-right">To</span>
                            <TimePicker 
                                :disabled="type === 'all-day'" 
                                v-model="endTime" 
                                class="d-inline-block" />
                            <div class="block-xs--xs-top">
                                <span class="block-xs--xs-right">Next Day</span>
                                <toggle 
                                    v-model="nextDay" 
                                    :slider="true" 
                                    class="d-inline-block" />
                            </div>
                        </div>
                    </div>
                </div>
            </line-item>


            <line-item 
                v-else 
                class="label-offset row no-gutters">
                <div class="col-12">
                    <tab-bar
                        :items="days.map(el => {
                            el.id = el.day
                            return el
                        })"
                        :action="item => activeDay = item.day"
                        :small="true" />
                </div>

              <template v-for="(day, key) in days">
                <div
                    v-if="day.day === activeDay"
                    :key="key"
                    class="block-xs--md-top row no-gutters">
                  <day-type-picker
                      :type="day.type"
                      :start="day.start"
                      :end="day.end"
                      :next="day.nextDay"
                      @updated="handleDayScheduleTypeChange(day.day, arguments[0])" />
                </div>
              </template>
            </line-item>
        </div>
    </div>
</template>

<script>
import lineItem from './line_item'
import toggle from '../components/cleverly/Toggle'
import tabBar from '../components/tab_bar'
import dayTypePicker from '../components/day_type_picker'
import TimePicker from '../components/TimePicker'

export default {
    name: 'WeeklyTimePicker',
    components: {
        lineItem,
        toggle,
        tabBar,
        dayTypePicker,
        TimePicker,
    },
    props: {
        start: {
            type: String,
            required: true,
        },
        end: {
            type: String,
            required: true,
        },
        next: {
            type: Boolean,
            required: true,
        },
        occurance: {
            type: String,
            required: true,
        },
        dayType: {
            type: String,
            required: true,
        },
        days: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            activeDay: 'mo',
        }
    },
    computed: {
        startTime: {
            get() {
                return this.start
            },
            set(val) {
                this.sendUpdate({start: val})
            },
        },
        endTime: {
            get() {
                return this.end
            },
            set(val) {
                this.sendUpdate({end: val})
            },
        },
        nextDay: {
            get() {
                return this.next
            },
            set(val) {
                this.sendUpdate({nextDay: val})
            },
        },
        schedule: {
            get() {
                return this.occurance
            },
            set(val) {
                this.sendUpdate({occurance: val})
            },
        },
        type: {
            get() {
                return this.dayType
            },
            set(val) {
                this.sendUpdate({dayType: val})
            },
        },
    },
    methods: {
        sendUpdate(changed = {}) {
            const payload = Object.assign({
                start: this.start,
                end: this.end,
                nextDay: this.next,
                occurance: this.occurance,
                dayType: this.dayType,
                days: this.days,
            }, changed)

            this.$emit('updated', payload)
        },
        handleDayScheduleTypeChange(dayKey, payload) {
            const days = {
                days: this.days.map(day => {
                    if (day.day === dayKey) {
                        return Object.assign(day, payload)
                    }

                    return day
                }),
            }

            this.sendUpdate(days)
        },
    },
}
</script>
