<template>
  <div v-if="store" class="store-settings-edit row no-gutters">
    <store-settings-header
      :pageTitle="pageTitle"
      :fuzzyTabs="true"
      :titleBarActions="titleBarActions"
      :editing="true"
      class="col-12"
      hide-env-selector
    />

    <div class="col-12">
      <div class="row no-gutters">
        <key-val-line prop="Store Online">
          <div class="d-flex block-xs--sm-bottom">
            <toggle v-model="store.storeOnline" :slider="true" class="block-xs--sm-left" />
          </div>
        </key-val-line>

        <key-val-line prop="Time">
          <weekly-time-picker
            :start="startTime"
            :end="endTime"
            :next="nextDay"
            :occurance="occurance"
            :dayType="dayType"
            :days="(typeof store.localStoreTimeWeekly === 'string') ? extractWeekHours(store.localStoreTimeWeekly) : store.localStoreTimeWeekly"
            @updated="updateTimeProps"
          />
        </key-val-line>

        <key-val-line prop="Prep Time Min">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="row no-gutters align-items-center">
                <form-input v-model="store.prepTimeMin" class="col-12 col-md-auto" />
                <span class="col block-xs--xs-top block-md--no-top block-md--xs-left">Minutes</span>
              </div>
            </div>
          </div>
        </key-val-line>

        <key-val-line prop="Prep Time Max">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="row no-gutters align-items-center">
                <form-input v-model="store.prepTimeMax" class="col-12 col-md-auto" />
                <span class="col block-xs--xs-top block-md--no-top block-md--xs-left">Minutes</span>
              </div>
            </div>
          </div>
        </key-val-line>

        <key-val-line prop="Phone Number">
          <form-input v-model="store.storePhoneNumber" />
        </key-val-line>

        <key-val-line prop="Email">
          <form-input v-model="store.storeEmail" />
        </key-val-line>

        <key-val-line prop="Employee Discount" v-if="canToggleProd">
          <form-input v-model="store.employeeDiscountValue" />
        </key-val-line>

        <key-val-line prop="Taxes">
          <div class="block-xs--xs-bottom">
            <form-input
              :value="taxes.state | parseTaxRate"
              class="d-inline-block"
              label="State"
              @change="taxes.state = arguments[0] / 100; taxesChanged = true"
            />%
          </div>

          <div class="block-xs--xs-bottom">
            <form-input
              :value="taxes.local | parseTaxRate"
              class="d-inline-block"
              label="Local"
              @change="taxes.local = arguments[0] / 100; taxesChanged = true"
            />%
          </div>

          <div>
            <form-input
              :value="taxes.other | parseTaxRate"
              class="d-inline-block"
              label="Other"
              @change="taxes.other = arguments[0] / 100; taxesChanged = true"
            />%
          </div>
        </key-val-line>
        <key-val-line prop="OAT Open Check Enabled">
          <toggle v-model="store.bOpenTicketEnabled" :slider="true" class="block-xs--sm-left" />
        </key-val-line>
        <!-- <key-val-line prop="OAT Text Reciept Enabled">
          <toggle v-model="store.bTextReceiptEnabled" :slider="true" class="block-xs--sm-left" />
        </key-val-line> -->
        <key-val-line prop="OAT Auto Close Tipping Enabled">
          <toggle v-model="store.tippingAutoAddOnClose" :slider="true" class="block-xs--sm-left" />
        </key-val-line>
        <key-val-line prop="OAT Auto Close Tipping Percent">
          <form-input v-model="store.tippingAutoPercentage" />
        </key-val-line>
      </div>
    </div>

    <EditingModal v-if="showWarning" size="sm">
      <AlertDialog
        :actions="warningActions"
        title="Are you sure?"
      >The tax changes you are about to save will affect every product in this store. Are you sure you want to make this change?</AlertDialog>
    </EditingModal>
  </div>
</template>

<script>
import clone from "clone";
import { mapState, mapGetters, mapActions } from "vuex";
import { extractWeekHours, parseTaxRate } from "helpers";
import storeSettingsHeader from "../components/store_settings_header";
import keyValLine from "../components/key_val_line";
import toggle from "../components/cleverly/Toggle";
import lineItem from "../components/line_item";
import formInput from "../components/cleverly/FormInput";
import selectInput from "../components/cleverly/SelectInput";
import inputList from "../components/input_list";
import weeklyTimePicker from "../components/weekly_time_picker";
import Upload from "components/Upload";
import ImageBlock from "components/image_block";
import AlertDialog from "components/alert_dialog";
import EditingModal from "components/editing_modal";

export default {
    name: "StoreSettingsEdit",
    data() {
        return {
            warningActions: [
                {
                    display: "Cancel",
                    type: "cancel",
                    run: () => {
                        this.showWarning = false;
                    }
                },
                {
                    display: "Continue",
                    type: "proceed",
                    run: () => {
                        this.save();
                    }
                }
            ],
            showWarning: false,
            taxesChanged: false,
            taxes: {
                state: 0,
                local: 0,
                other: 0
            },
            pageTitle: [
                { to: { name: "store-settings" }, label: "Store Settings" },
                "Edit"
            ],
            baseOccurance: false,
            baseDayType: false,
            startTime: "12:00 AM",
            endTime: "11:59 PM",
            nextDay: false,
            titleBarActions: [
                {
                    type: "cancel",
                    display: "Cancel",
                    run: () => {
                        this.$router.push({ name: "store-settings" });
                    }
                },
                {
                    type: "proceed",
                    display: "Save",
                    run: () => {
                        if (this.taxesChanged) {
                            this.showWarning = true;
                        } else {
                            this.save();
                        }
                    }
                }
            ],
            turnaroundOptions: [
                { text: "0 minutes", value: 0 },
                { text: "1 minutes", value: 1 },
                { text: "2 minutes", value: 2 },
                { text: "3 minutes", value: 3 },
                { text: "4 minutes", value: 4 },
                { text: "5 minutes", value: 5 },
                { text: "6 minutes", value: 6 },
                { text: "7 minutes", value: 7 },
                { text: "8 minutes", value: 8 },
                { text: "9 minutes", value: 9 },
                { text: "10 minutes", value: 10 }
            ],
            operators: [
                { value: "Delaware North", text: "Delaware North" },
                { value: "Delaware West", text: "Delaware West" }
            ],
            store: false,
            uploadedImages: []
        };
    },
    computed: {
        occurance: {
            get() {
                if (this.baseOccurance) {
                    return this.baseOccurance;
                }

                const days = extractWeekHours(this.store.localStoreTimeWeekly);
                let uniqueDays = false;

                days.sort((a, b) => {
                    if (a.start !== b.start || a.end !== b.end) {
                        uniqueDays = true;
                    }

                    return 0;
                });

                return uniqueDays ? "custom" : "everyday";
            },
            set(val) {
                this.baseOccurance = val;
            }
        },
        dayType: {
            get() {
                if (this.baseDayType) {
                    return this.baseDayType;
                }

                const days = extractWeekHours(this.store.localStoreTimeWeekly);

                return days.find(
                    day => day.start === "12:00 AM" && day.end === "11:59 PM"
                ) // This does not seem like it will work
                    ? "all-day"
                    : "custom";
            },
            set(val) {
                this.baseDayType = val;
            }
        },
        canToggleProd() {
            return this.superuser
        },
        ...mapState({
            imageServer: state => state.imageServer
        }),
        ...mapGetters({
            baseStore: "store"
        }),
        ...mapGetters(["user", "superuser"])
    },
    methods: {
        save() {
            this.$router.push({ name: "store-settings" });

            this.saveStore();
        },
        saveStore() {
            const days = this.formatDayProps();
            const payload = {
                storeWaypointID: this.store.storeWaypointID,
                storeOnline: this.store.storeOnline,
                prepTimeMin: this.store.prepTimeMin,
                prepTimeMax: this.store.prepTimeMax,
                storePhoneNumber: this.store.storePhoneNumber,
                storeEmail: this.store.storeEmail,
                employeeDiscountType: this.store.employeeDiscountType,
                employeeDiscountValue: this.store.employeeDiscountValue,
                employeeDiscountOwner: this.store.employeeDiscountOwner
            };

            days.forEach(day => {
                Object.keys(day).forEach(el => {
                    payload[el] = day[el];
                });
            });

            if (this.taxesChanged) {
                this.saveStoreTaxes({
                    stateTax: this.taxes.state,
                    localTax: this.taxes.local,
                    otherTax: this.taxes.other
                }).then(() => {
                    this.saveStoreSettings(payload);
                });
            } else {
                this.saveStoreSettings(payload);
            }
        },
        formatDayProps() {
            const days =
        typeof this.store.localStoreTimeWeekly === "string"
            ? extractWeekHours(this.store.localStoreTimeWeekly)
            : this.store.localStoreTimeWeekly;

            const baseStart = "12:00 AM";
            const baseEnd = "11:59 PM";

            if (this.occurance === "everyday") {
                const isCustom = this.dayType === "custom";
                const start = isCustom ? this.startTime : baseStart;
                const end = isCustom ? this.endTime : baseEnd;
                const endDay = this.nextDay ? 2 : 1;
                const startStamp = `1/1/1900 ${start}`;
                const endStamp = `1/${endDay}/1900 ${end}`;

                return days.map(day => ({
                    [`localStartTime${day.fullDisplay}`]: startStamp,
                    [`localEndTime${day.fullDisplay}`]: endStamp
                }));
            }

            return days.map(day => {
                const isCustom = day.type === "custom";
                const start = isCustom ? day.start : baseStart;
                const end = isCustom ? day.end : baseEnd;
                const endDay = day.nextDay ? 2 : 1;
                const startStamp = `1/1/1900 ${start}`;
                const endStamp = `1/${endDay}/1900 ${end}`;

                return {
                    [`localStartTime${day.fullDisplay}`]: startStamp,
                    [`localEndTime${day.fullDisplay}`]: endStamp
                };
            });
        },
        updateTimeProps({ start, end, occurance, nextDay, dayType, days }) {
            this.startTime = start;
            this.endTime = end;
            this.occurance = occurance;
            this.nextDay = nextDay;
            this.dayType = dayType;
            this.store.localStoreTimeWeekly = days;
        },
        extractWeekHours,
        uploadImage({ imageBase64, fileName }) {
            const storeID = this.store.storeID;

            this.uploadedImages = [imageBase64];
            this.uploadStoreImage({
                imageBase64,
                storeID,
                fileName:
          Math.floor(Math.random() * 100) + "_" + fileName.replace(" ", "_")
            });
        },
        setInitialTime() {
            if (this.occurance === "everyday") {
                const days = extractWeekHours(this.store.localStoreTimeWeekly);

                if (days) {
                    const day = days[0];

                    this.startTime = day.start;
                    this.endTime = day.end;
                    this.nextDay = day.nextDay;
                }
            }
        },
        resolvePageTitle() {
            if (Object.keys(this.baseStore).length) {
                document.title = `${this.baseStore.storeName} - Edit Settings`;
            }
        },
        resolveTaxes() {
            this.baseStore.taxes.forEach(tax => {
                const key = tax.Description.toLowerCase();

                if (this.taxes[key] !== undefined) {
                    this.taxes[key] = tax.Amount;
                }
            });
        },
        ...mapActions(["saveStoreSettings", "saveStoreTaxes", "uploadStoreImage"])
    },

    filters: {
        parseTaxRate
    },

    components: {
        storeSettingsHeader,
        keyValLine,
        toggle,
        lineItem,
        formInput,
        selectInput,
        inputList,
        weeklyTimePicker,
        Upload,
        ImageBlock,
        AlertDialog,
        EditingModal
    },
    watch: {
        baseStore(val) {
            this.store = clone(val);

            this.resolvePageTitle();
            this.setInitialTime();
            this.resolveTaxes();
        },
        imageServer() {
            this.uploadedImages = [this.imageServer + this.store.storeImageName];
        }
    },
    mounted() {
        this.resolvePageTitle();

        if (Object.keys(this.baseStore).length) {
            this.store = clone(this.baseStore);

            this.uploadedImages = [this.imageServer + this.store.storeImageName];

            this.setInitialTime();
            this.resolveTaxes();
        }
    }
};
</script>

<style lang="scss">
@import "~scss/variables";
@import "~scss/mixins";

.store-settings-edit {
  .form-input input {
    @include below(xs) {
      width: 100%;
    }
  }
}
</style>
