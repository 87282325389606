var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.store
    ? _c(
        "div",
        { staticClass: "store-settings-edit row no-gutters" },
        [
          _c("store-settings-header", {
            staticClass: "col-12",
            attrs: {
              pageTitle: _vm.pageTitle,
              fuzzyTabs: true,
              titleBarActions: _vm.titleBarActions,
              editing: true,
              "hide-env-selector": "",
            },
          }),
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "row no-gutters" },
              [
                _c("key-val-line", { attrs: { prop: "Store Online" } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex block-xs--sm-bottom" },
                    [
                      _c("toggle", {
                        staticClass: "block-xs--sm-left",
                        attrs: { slider: true },
                        model: {
                          value: _vm.store.storeOnline,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "storeOnline", $$v)
                          },
                          expression: "store.storeOnline",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "key-val-line",
                  { attrs: { prop: "Time" } },
                  [
                    _c("weekly-time-picker", {
                      attrs: {
                        start: _vm.startTime,
                        end: _vm.endTime,
                        next: _vm.nextDay,
                        occurance: _vm.occurance,
                        dayType: _vm.dayType,
                        days:
                          typeof _vm.store.localStoreTimeWeekly === "string"
                            ? _vm.extractWeekHours(
                                _vm.store.localStoreTimeWeekly
                              )
                            : _vm.store.localStoreTimeWeekly,
                      },
                      on: { updated: _vm.updateTimeProps },
                    }),
                  ],
                  1
                ),
                _c("key-val-line", { attrs: { prop: "Prep Time Min" } }, [
                  _c("div", { staticClass: "row no-gutters" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "row no-gutters align-items-center" },
                        [
                          _c("form-input", {
                            staticClass: "col-12 col-md-auto",
                            model: {
                              value: _vm.store.prepTimeMin,
                              callback: function ($$v) {
                                _vm.$set(_vm.store, "prepTimeMin", $$v)
                              },
                              expression: "store.prepTimeMin",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass:
                                "col block-xs--xs-top block-md--no-top block-md--xs-left",
                            },
                            [_vm._v("Minutes")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("key-val-line", { attrs: { prop: "Prep Time Max" } }, [
                  _c("div", { staticClass: "row no-gutters" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "row no-gutters align-items-center" },
                        [
                          _c("form-input", {
                            staticClass: "col-12 col-md-auto",
                            model: {
                              value: _vm.store.prepTimeMax,
                              callback: function ($$v) {
                                _vm.$set(_vm.store, "prepTimeMax", $$v)
                              },
                              expression: "store.prepTimeMax",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass:
                                "col block-xs--xs-top block-md--no-top block-md--xs-left",
                            },
                            [_vm._v("Minutes")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "key-val-line",
                  { attrs: { prop: "Phone Number" } },
                  [
                    _c("form-input", {
                      model: {
                        value: _vm.store.storePhoneNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.store, "storePhoneNumber", $$v)
                        },
                        expression: "store.storePhoneNumber",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "key-val-line",
                  { attrs: { prop: "Email" } },
                  [
                    _c("form-input", {
                      model: {
                        value: _vm.store.storeEmail,
                        callback: function ($$v) {
                          _vm.$set(_vm.store, "storeEmail", $$v)
                        },
                        expression: "store.storeEmail",
                      },
                    }),
                  ],
                  1
                ),
                _vm.canToggleProd
                  ? _c(
                      "key-val-line",
                      { attrs: { prop: "Employee Discount" } },
                      [
                        _c("form-input", {
                          model: {
                            value: _vm.store.employeeDiscountValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.store, "employeeDiscountValue", $$v)
                            },
                            expression: "store.employeeDiscountValue",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("key-val-line", { attrs: { prop: "Taxes" } }, [
                  _c(
                    "div",
                    { staticClass: "block-xs--xs-bottom" },
                    [
                      _c("form-input", {
                        staticClass: "d-inline-block",
                        attrs: {
                          value: _vm._f("parseTaxRate")(_vm.taxes.state),
                          label: "State",
                        },
                        on: {
                          change: function ($event) {
                            _vm.taxes.state = arguments[0] / 100
                            _vm.taxesChanged = true
                          },
                        },
                      }),
                      _vm._v("% "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "block-xs--xs-bottom" },
                    [
                      _c("form-input", {
                        staticClass: "d-inline-block",
                        attrs: {
                          value: _vm._f("parseTaxRate")(_vm.taxes.local),
                          label: "Local",
                        },
                        on: {
                          change: function ($event) {
                            _vm.taxes.local = arguments[0] / 100
                            _vm.taxesChanged = true
                          },
                        },
                      }),
                      _vm._v("% "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("form-input", {
                        staticClass: "d-inline-block",
                        attrs: {
                          value: _vm._f("parseTaxRate")(_vm.taxes.other),
                          label: "Other",
                        },
                        on: {
                          change: function ($event) {
                            _vm.taxes.other = arguments[0] / 100
                            _vm.taxesChanged = true
                          },
                        },
                      }),
                      _vm._v("% "),
                    ],
                    1
                  ),
                ]),
                _c(
                  "key-val-line",
                  { attrs: { prop: "OAT Open Check Enabled" } },
                  [
                    _c("toggle", {
                      staticClass: "block-xs--sm-left",
                      attrs: { slider: true },
                      model: {
                        value: _vm.store.bOpenTicketEnabled,
                        callback: function ($$v) {
                          _vm.$set(_vm.store, "bOpenTicketEnabled", $$v)
                        },
                        expression: "store.bOpenTicketEnabled",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "key-val-line",
                  { attrs: { prop: "OAT Auto Close Tipping Enabled" } },
                  [
                    _c("toggle", {
                      staticClass: "block-xs--sm-left",
                      attrs: { slider: true },
                      model: {
                        value: _vm.store.tippingAutoAddOnClose,
                        callback: function ($$v) {
                          _vm.$set(_vm.store, "tippingAutoAddOnClose", $$v)
                        },
                        expression: "store.tippingAutoAddOnClose",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "key-val-line",
                  { attrs: { prop: "OAT Auto Close Tipping Percent" } },
                  [
                    _c("form-input", {
                      model: {
                        value: _vm.store.tippingAutoPercentage,
                        callback: function ($$v) {
                          _vm.$set(_vm.store, "tippingAutoPercentage", $$v)
                        },
                        expression: "store.tippingAutoPercentage",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.showWarning
            ? _c(
                "EditingModal",
                { attrs: { size: "sm" } },
                [
                  _c(
                    "AlertDialog",
                    {
                      attrs: {
                        actions: _vm.warningActions,
                        title: "Are you sure?",
                      },
                    },
                    [
                      _vm._v(
                        "The tax changes you are about to save will affect every product in this store. Are you sure you want to make this change?"
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }