<template>
    <div class="input-list">
        <div 
            v-for="(input, key) in inputs" 
            :key="key" 
            class="input-list__item">
            <form-input 
                v-model="input.value" 
                @input="onInput" />
            <div 
                class="input-list__remove icon icon--remove block-xs--xs-left" 
                @click="removeInput(key)"/>
        </div>

        <div 
            class="input-list__add block-xs--sm-top" 
            @click="addInput">
            {{ addMessage }}
        </div>
    </div>
</template>

<script>
import formInput from './cleverly/FormInput.vue'

export default {
    name: 'InputList',
    components: {
        formInput,
    },
    props: {
        items: Array,
        addMessage: {
            type: String,
            default: () => 'Add',
        },
    },
    data() {
        return {
            inputs: [],
        }
    },
    created() {
        this.inputs = this.items
    },
    methods: {
        onInput() {
            this.$emit('change', this.inputs)
        },
        addInput() {
            this.inputs.push({value: ''})
            this.onInput()
        },
        removeInput(index) {
            this.inputs.splice(index, 1)
            this.onInput()
        },
    },
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.input-list {
    &__item {
        display: flex;
        align-items: center;

        margin-bottom: spacing(sm);
    }

    &__remove, &__add {
        cursor: pointer;
    }

    &__add {
        color: $brand_color;
    }
}
</style>
