var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-list" },
    [
      _vm._l(_vm.inputs, function (input, key) {
        return _c(
          "div",
          { key: key, staticClass: "input-list__item" },
          [
            _c("form-input", {
              on: { input: _vm.onInput },
              model: {
                value: input.value,
                callback: function ($$v) {
                  _vm.$set(input, "value", $$v)
                },
                expression: "input.value",
              },
            }),
            _c("div", {
              staticClass:
                "input-list__remove icon icon--remove block-xs--xs-left",
              on: {
                click: function ($event) {
                  return _vm.removeInput(key)
                },
              },
            }),
          ],
          1
        )
      }),
      _c(
        "div",
        {
          staticClass: "input-list__add block-xs--sm-top",
          on: { click: _vm.addInput },
        },
        [_vm._v(" " + _vm._s(_vm.addMessage) + " ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }